
    import { mapActions } from 'vuex';
    import BaseModal from './BaseModal';

    export default {
        name: 'PlayAgainModal',

        extends: BaseModal,

        data () {
            return {
                modalName: 'playAgain',
            };
        },

        methods: {
            ...mapActions({
                awardEvent: 'award/awardEvent',
            }),
            // This is just example code for the IDs on how it should be set up.
            // The spin to win logic needs to be included in Inky as well
            async goToBonusGame () {
                const event = this.$store.state.profile.DAHE_member ? 'memberBonusPlay' :
                    'bonusPlay';

                // generate the token before going to the IW game
                // to help limit token banking
                await this.awardEvent({ event });

                window.vueApp.$router.replace('/game');
                this.closeModal();
            },
        },

        i18nOptions: { namespaces: ['modal_content'] },
    };
