
const getDefaultState = () => ({
    pageName: 'default',
    pageClasses: [],
    modalName: null,
    rapidTemplate: false && "a",
    showCheckDisclaimer: false,
    showEcardDisclaimer: false,
    showFrankfordDisclaimer: false,
    showKeurigDiscalimer: false,
    showReceiptDisclaimer: false,
});

const state = getDefaultState();

const getters = {
    // convenience getter for elements that need to know when modals are open/closed
    // such as adding dynamic attributes to help create modal locks (inert, aria-hidden, etc).
    // ex: :aria-hidden="String(this.$store.getters['ui/isModalOpen'])" vs
    // :aria-hidden="String(!!this.$store.state.ui.modalName)"
    isModalOpen: (state) => !!state.modalName,
};

const { body } = document;
const LOADING_CLASS = 'page-loading';

const mutations = {
    pageEnter (state, newPage) {
        if (state.pageClasses && state.pageClasses.length) {
            body.classList.remove(...state.pageClasses);
        }

        state.pageName = newPage.pageName || newPage.$options.name;
        state.pageClasses = newPage.pageClasses;

        body.id = state.pageName;

        if (body.classList.contains(LOADING_CLASS)) {
            body.classList.remove(LOADING_CLASS);
        }
        if (state.pageClasses && state.pageClasses.length) {
            body.classList.add(...state.pageClasses);
        }
        state.showCheckDisclaimer = false;
        state.showEcardDisclaimer = false;
        state.showFrankfordDisclaimer = false;
        state.showKeurigDiscalimer = false;
        state.showReceiptDisclaimer = false;
        state.showVerifyDisclaimer = false;
    },
    pageLeave (state) {
        body.classList.add(LOADING_CLASS);
    },

    modalOpen (state, newModal) {
        state.modalName = newModal.modalName;
    },
    modalClose (state) {
        state.modalName = null;
    },
    showCheckDisclaimer (state) {
        state.showCheckDisclaimer = true;
    },
    showEcardDisclaimer (state) {
        state.showEcardDisclaimer = true;
    },
    showFrankfordDisclaimer (state) {
        state.showFrankfordDisclaimer = true;
    },
    showKeurigDiscalimer (state) {
        state.showKeurigDiscalimer = true;
    },
    showReceiptDisclaimer (state) {
        state.showReceiptDisclaimer = true;
    },
    showVerifyDisclaimer (state) {
        state.showVerifyDisclaimer = true;
    },
};

const actions = {
    showCheckDisclaimer ({ commit }) {
        commit('showCheckDisclaimer');
    },

    showFrankfordDisclaimer ({ commit }) {
        commit('showFrankfordDisclaimer');
    },

    showKeurigDiscalimer ({ commit }) {
        commit('showKeurigDiscalimer');
    },

    showEcardDisclaimer ({ commit }) {
        commit('showEcardDisclaimer');
    },

    showReceiptDisclaimer ({ commit }) {
        commit('showReceiptDisclaimer');
    },

    showVerifyDisclaimer ({ commit }) {
        commit('showVerifyDisclaimer');
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
