
import axios from 'axios';
import { reactive, set } from 'vue';
import api from '../../api';
import { setRecaptchaV3Challenge } from '../../recaptcha';

const getDefaultState = () => reactive({
    awards: null,
    prizeWon: null,
    hasBonusPlay: false,
    canSpinToWin: false,
    showPlayAgainModal: false,
    allowInToMemberGame: false,
});

const state = getDefaultState();

const getters = {
    uuid: (state) => (state.prizeWon?.id || '').replace(/-/g, ''),
};

const mutations = {
    updateAwardsState (state, data) {
        // only update what we want/need
        const stateKeys = ['awards', 'prizeWon', 'hasBonusPlay', 'canSpinToWin', 'showPlayAgainModal', 'allowInToMemberGame'];
        for (const key of stateKeys) {
            if (data[key] !== undefined) {
                set(state, key, data[key]);
            }
        }
    },

    updateAwards (state, awards) {
        state.awards = awards;
    },
    updatePrizeWon (state, prizeWon) {
        state.prizeWon = prizeWon;
    },
};

const actions = {
    async getAwards ({ dispatch }) {
        // hasBonusPlay and canSpinToWin are also returned here
        // if the user can earn those awards. The fields are set
        // by testing awarding limits without generating IW tokens,
        // this is done to prevent token banking. The front-end will
        // need to generate the tokens via awarEvent when the user elects to play again
        // or go to the spin to win game.
        return dispatch('makeCall', {
            type: 'get',
            endpoint: 'awards',
        });
    },

    async awardEvent ({ dispatch }, { event }) {
        const data = {};
        await setRecaptchaV3Challenge({ data, action: 'award' });
        return dispatch('makeCall', {
            endpoint: `awards/events/${encodeURIComponent(event)}:award`,
            data,
        });
    },

    async play ({ dispatch, state }) {
        const data = {};
        await setRecaptchaV3Challenge({ data, action: 'play' });
        // If the user has not played twice, they can play again
        // but are not forced to play again.
        // If it is a member, they can chose to play again
        // or they can play the spin to win game if they are not limited from it.
        // The /:play API will return the core response in addition to 2 new top-level
        // fields. The fields are: hasBonusPlay (bool) and canSpinToWin (bool)
        // use them for routing purposes.
        // The IW tokens need to be generated on "play again" or "spin now" button click
        // this is intentional to prevent token banking.
        await dispatch('makeCall', {
            endpoint: 'awards/:play',
            data,
        });
        return state.prizeWon;
    },

    // Member prize wheel play endpoint
    async playMemberGame ({ dispatch, state }) {
        const data = {};
        await setRecaptchaV3Challenge({ data, action: 'spin_play' });

        try {
            await dispatch('makeCall', {
                endpoint: 'awards/:members/:play',
                data,
            });

            return {
                prizeWon: state.prizeWon,
                pointsWon: state.pointsWon,
            };
        }
        catch (err) {
            if (err.response?.status === 401) {
                console.error('session-timeout');

                dispatch('profile/logOut', null, { root: true });

                // TODO: update this to the actual route for the members only
                // prize-wheel game.
                try { window.sessionStorage.setItem('backTo', 'member-game'); }
                catch (e) { console.error('sessionStorage error', e); }

                window.vueApp.$router.push('/error/session-timeout');

                throw new Error('throwing to stop mount call');
            }
        }
    },

    async makeCall ({ commit }, {
        type = 'post',
        endpoint,
        data,
    }) {
        let response;

        try {
            response = await axios[type](`${api.base}/${endpoint}`, data);
        }
        catch (err) {
            if (err.response?.status === 429) {
                // User was only limited, carry on
                ({ response } = err);
            }
            else {
                console.error(
                    `error making ${endpoint} call`,
                    err.message,
                    err,
                );

                throw err;
            }
        }

        commit('updateAwardsState', response?.data || {});

        return response;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
