import store from '../store';
// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    GamePage = async () => import(
        /* webpackChunkName: 'game' */
        '../views/GamePage.vue'
    ),
    MemberGamePage = async () => import(
        /* webpackChunkName: 'member-game' */
        '../views/GameMemberPage.vue'
    ),
    InkyPage = async () => import(
        /* webpackChunkName: 'game' */
        '../views/InkyPage.vue'
    ),
    JankyPage = async () => import(
        /* webpackChunkName: 'game' */
        '../views/JankyPage.vue'
    );

export default [
    { path: '/game',  component: GamePage },
    { path: '/inky',  component: InkyPage },
    { path: '/janky', component: JankyPage },
    { path: '/limiter', component: JankyPage },
    { path: '/member-game', component: MemberGamePage, beforeEnter: (to, from, next) => {
        if (!store.state.award.allowInToMemberGame) {
            return next('/janky');
        }
        return next();
    } },
];
