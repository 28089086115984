
    import BaseModal from './BaseModal';

    export default {
        name: 'WelcomeModal',

        extends: BaseModal,

        i18nOptions: { namespaces: ['modal_content'] },

        data () {
            return {
                modalName: 'welcome',
            };
        },
    };
