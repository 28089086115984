
    import BaseModal from './BaseModal';

    export default {
        name: 'MemberGameOverModal',

        extends: BaseModal,

        props: {
            uuid: {
                type: String,
                required: true,
            },
        },

        data () {
            return {
                modalName: 'MemberGameOver',
            };
        },

        methods: {
            goToLimiter () {
                window.vueApp.$router.replace('/janky');
                this.closeModal();
            },
        },

        i18nOptions: { namespaces: ['prizewheel'] },
    };
