export default {
    methods: {
        fbTrackAndExit (location) {
            window.fbq('track', 'Exit');
            if (location) {
                window.location = location;
            }
        },
    },
};
