
    import fbTrackAndExit from '@public/js/mixins/fbTrackAndExit';
    import BasePage from './BasePage';

    export default {
        name: 'ErrorPage',

        extends: BasePage,

        mixins: [fbTrackAndExit],

        props: {
            error: {
                type: String,
                default: 'generic',
            },
        },

        data () {
            return {
                pageName: 'error',
            };
        },

        computed: {
            isSessionTimeout () {
                return this.error == 'session-timeout';
            },
        },

        i18nOptions: { namespaces: ['error', 'buttons'] },
    };
