
import store from '../store/index';

// Asynchronous component definitions for lazy-loading routes.
// https://v3.router.vuejs.org/guide/advanced/lazy-loading.html
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
// https://webpack.js.org/api/module-methods/#magic-comments
const
    DuplicateAccountPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/DuplicateAccountPage.vue'
    ),
    IneligibleAgePage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/IneligibleAgePage.vue'
    ),
    LandingPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LandingPage.vue'
    ),
    LoginPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/LoginPage.vue'
    ),
    RegisterPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/RegisterPage.vue'
    ),
    RulesPage = async () => import(
        /* webpackChunkName: 'profile' */
        '../views/RulesPage.vue'
    );

export default [
    { path: '/', redirect: (to) => {
        // Used to send server-side errors to the '/error' page.
        if (store.state.app.defaultRoute) {
            return store.state.app.defaultRoute;
        }
        // Default routes for logged-in users.
        else if (
            store.getters['profile/loggedIn'] &&
            !store.getters['profile/isSessionExpired']
        ) {
            console.log('logged in');

            let target;

            try { target = window.sessionStorage.getItem('backTo'); }
            catch (err) { console.error('sessionStorage error', err); }

            // Members can accept Ts&Cs on the promotion
            // or from DAHE, they could hit a "logged in" state
            // but not have accepted the rules, so we need to force them to
            if (
                store.state.profile.DAHE_member &&
                !store.state.profile.rules
            ) {
                return '/rules';
            }
            else if (store.state.award.awards?.isLimited) {
                return '/limiter';
            }
            else if (target) {
                try { window.sessionStorage.removeItem('backTo'); }
                catch (err) { console.error('sessionStorage error', err); }

                return `/${target}`;
            }
            else {
                return '/game';
            }
        }

        // Default route for a user we know nothing about.
        return '/landing';
    } },
    { path: '/landing', component: LandingPage, meta: { public: true } },
    { path: '/login', component: LoginPage, meta: { public: true } },
    { path: '/register', component: RegisterPage, meta: { public: true } },
    { path: '/ineligible_age', component: IneligibleAgePage, meta: { public: true } },
    { path: '/dup_account', component: DuplicateAccountPage, meta: { public: true } },
    { path: '/rules', component: RulesPage },
    { path: '/logout', redirect: (to) => {
        store.dispatch('profile/logOut');
        return '/';
    } },
];
